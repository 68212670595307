const sales = [
  {
    id: 1,
    img: '/images/icons/scope-of-work.png',
    name: 'Scope of Work',
    link: '/scope-of-work',
  },
  {
    id: 2,
    img: '/images/icons/po-status.jpg',
    name: 'PO Status',
    link: '/po-status',
  },
  {
    id: 3,
    img: '/images/icons/project-approval.png',
    name: 'Project Approval',
    link: '/project-approval',
  },
];

export default sales;

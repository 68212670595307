import React from 'react';
import './NotFoundPage.css';

function NotFoundPage() {
  return (
    <div className="notfound_container  ">
      <div className="content">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>Oops! The page you're looking for doesn't exist.</p>
      </div>
    </div>
  );
}

export default NotFoundPage;

import './policy.css';
import PolicyCard from './PolicyCard';

const PolicyDocHome = () => {
  return (
    <div className="container">
      <h3 className="fw-bold text-center">Taypro's Policies</h3>
      <PolicyCard />
    </div>
  );
};

export default PolicyDocHome;

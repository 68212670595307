// import React from 'react';

// const RecyclingOfLifepo4Battery = () => {
//   return <div className="container">RecyclingOfLifepo4Battery</div>;
// };

// export default RecyclingOfLifepo4Battery;

import React from 'react';
import PolicyFooter from '../PolicyFooter';
import { Helmet } from 'react-helmet';

// import logo from './Taypro.png';
const RecyclingOfLifepo4Battery = () => {
  const logo =
    'https://res.cloudinary.com/di0iwc8ql/image/upload/v1709110699/gsqahyovjyqommmfi10z.png';

  return (
    <div className="policy-container py-1">
      <Helmet>
        <title>Recycling Of Lifepo4 Battery</title>
      </Helmet>
      <div className=" policy-heading-container">
        <img src={logo} className="policy-logo " alt="" />
        <h3 className="policy-heading">Recycling Of Life-po4 Battery🔋</h3>
      </div>

      <section className="policy-section1">
        <h5 className="policy-section1-heading">
          1] Recycling procedure for LiFePO4 Batteries used in the cleaning
          robots
        </h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            Recycling LiFePO4 (Lithium Iron Phosphate) batteries is crucial due
            to the valuable materials they contain and the potential
            environmental impacts if they are disposed of improperly. The
            recycling procedure for LiFePO4 batteries is quite technical and
            involves both mechanical and chemical processes. In the context of
            the Indian regulations, as of my last update in January 2022, the
            "Battery Management and Handling Rules, 2001," under the Environment
            (Protection) Act of 1986, primarily covers lead-acid batteries. For
            lithium batteries, specific regulations and guidelines may vary and
            evolve over time. It's essential to stay updated with the Central
            Pollution Control Board (CPCB) and the Ministry of Environment,
            Forest, and Climate Change (MoEFCC) for the latest regulations.
          </span>
          <span className="second-para px-4 pt-2">
            Below is a general recycling procedure for LiFePO4 batteries:
          </span>
        </div>
      </section>

      <section className="policy-section1 my-3">
        <h5 className="policy-section1-heading">2] Recycling Procedure:</h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            <span className="fw-bold"> Step 1:</span>{' '}
            <b>Collection and Transportation</b>
            <ol>
              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Battery Inspection:</b> Examine the collected batteries for
                any leakage or damage.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b> Safe Packaging:</b> Store the batteries in insulated and
                labelled containers for transportation.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Licensed Transport:</b> Use transportation methods licensed
                to carry hazardous waste, ensuring no environmental spillage or
                contamination.
              </li>
            </ol>
          </span>

          <span className="first-para px-4">
            <span className="fw-bold"> Step 2:</span> <b>Pre-Processing</b>
            <ol>
              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Discharge:</b> Ensure the batteries are fully discharged to
                minimize risks.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Mechanical Processing:</b> Use shredders to crush the
                batteries into smaller pieces.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Sorting:</b> Separate the crushed materials into three parts:
                metallic components, plastics, and the cathode and anode
                materials.
              </li>
            </ol>
          </span>

          <span className="first-para px-4">
            <span className="fw-bold"> Step 3:</span>{' '}
            <b>Pyrometallurgical (Smelting) Process</b>
            <ol>
              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Discharge:</b> Ensure the batteries are fully discharged to
                minimize risks.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Mechanical Processing:</b> Use shredders to crush the
                batteries into smaller pieces.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Sorting:</b> Separate the crushed materials into three parts:
                metallic components, plastics, and the cathode and anode
                materials.
              </li>
            </ol>
          </span>

          <span className="first-para px-4">
            <span className="fw-bold"> Step 4:</span>{' '}
            <b> Hydrometallurgical Process</b>
            <ol>
              <li style={{ listStyleType: 'number' }} className="p-1">
                <b> Leaching:</b> The cathode and anode materials undergo a
                leaching process using chemicals to dissolve the metals.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b> Precipitation: </b>Chemicals are added to the solution to
                cause the metals to precipitate out.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Filtration: </b> The solution is filtered to separate the
                solid precipitates.
              </li>

              <li style={{ listStyleType: 'number' }} className="p-1">
                <b>Recovery: </b> Recover lithium and other metals from the
                solid residue.
              </li>
            </ol>
          </span>
        </div>
      </section>

      <PolicyFooter />
    </div>
  );
};

export default RecyclingOfLifepo4Battery;

const policies = [
  {
    id: 1,
    img: '/images/policy/anti-harassment.png',
    name: 'Anti Harrasment',
    link: '/anti-harrasment',
  },
  {
    id: 2,
    img: '/images/policy/late-coming.png',
    name: 'Late Coming',
    link: '/late-coming',
  },
  {
    id: 3,
    img: '/images/policy/office-rules.png',
    name: 'Office Rules and Regulations',
    link: '/office-rules-and-regulations',
  },
  {
    id: 4,
    img: '/images/policy/paid-holidays.png',
    name: 'Paid Holidays',
    link: '/calender',
  },
  {
    id: 5,
    img: '/images/policy/paid-leaves.png',
    name: 'Paid Leaves',
    link: '/paid-leaves',
  },
  {
    id: 6,
    img: '/images/policy/termination.png',
    name: 'Termination',
    link: '/termination',
  },
  {
    id: 7,
    img: '/images/policy/travel.webp',
    name: 'Travel',
    link: '/travel-policy',
  },
  {
    id: 8,
    img: '/images/policy/staff.jpg',
    name: 'Staff',
    link: '/staff-policy',
  },
  {
    id: 8,
    img: '/images/policy/refral.webp',
    name: 'Referal Bonus Policy',
    link: '/referral-bonus-policy',
  },
  // {
  //   id: 9,
  //   img: '/images/policy/factory.png',
  //   name: 'Factory',
  //   link: '/factory-policy-home',
  // },
];

export default policies;

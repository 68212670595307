const FactoryPolicies = [
  {
    id: 1,
    img: '/images/policy/recycle-battery.webp',
    name: 'Recycling of LiFePO4 Battery',
    link: '/recycling-of-lifepo4-battery', // Corrected the link
  },
  {
    id: 2,
    img: '/images/policy/recycle-body.jpg',
    name: 'Recycling of Aluminium Body of Robot',
    link: '/recycling-of-aluminium-body-of-robot', // Corrected the link
  },
  {
    id: 3,
    img: '/images/policy/recycle-harness.jpg',
    name: 'Recycling of Wiring Harness',
    link: '/recycling-of-wiring-harness', // Corrected the link
  },
  {
    id: 4,
    img: '/images/policy/recycle-microfiber.jpg',
    name: 'Recycling of Microfiber cloth',
    link: '/recycling-of-microfiber-cloth', // Corrected the link
  },
  {
    id: 5,
    img: '/images/policy/recycle-other.svg',
    name: 'Recycling of Other Components of Robots',
    link: '/recycling-of-other-components', // Corrected the link
  },
  {
    id: 6,
    img: '/images/policy/recycle-pcb.avif',
    name: 'Recycling of PCB’s',
    link: '/recycling-of-pcbs', // Corrected the link
  },
  {
    id: 7,
    img: '/images/policy/recycle-wheel.jpg',
    name: 'Recycling of Wheels',
    link: '/recycling-of-wheels', // Corrected the link
  },
];

export default FactoryPolicies;

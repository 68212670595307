import React from 'react';
import TableWithSearchAndPagination from './TableWithSearchAndPagination';

const Table = () => {
  return (
    <div>
      <TableWithSearchAndPagination />
    </div>
  );
};

export default Table;

import React from 'react';
import PolicyFooter from './PolicyFooter';
import { Helmet } from 'react-helmet';

const Termination = () => {
  const logo =
    'https://res.cloudinary.com/di0iwc8ql/image/upload/v1709110699/gsqahyovjyqommmfi10z.png';

  return (
    <div className="policy-container py-1">
      <Helmet>
        <title>Termination Policy</title>
      </Helmet>
      <div className=" policy-heading-container">
        <img src={logo} className="policy-logo " alt="" />
        <h3 className="policy-heading">Termination Policy</h3>
      </div>

      <section className="policy-section1">
        <h5 className="policy-section1-heading">1] Objectives</h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            It is the policy of TAYPRO PVT.LTD Systems to ensure that employee
            terminations, including voluntary and involuntary terminations and
            terminations due to the death of an employee, are handled in a
            professional manner with minimal disruption to the workplace. <br />
            <br />
          </span>
        </div>
      </section>

      <section className="policy-section1">
        <h5 className="policy-section1-heading">2] Applicability</h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            All Employee in the organisation <br />
            <br />
          </span>
        </div>
      </section>

      <section className="policy-section1">
        <h5 className="policy-section1-heading">3] Guidline</h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4"></span>
        </div>
        <h5 className="policy-section1-heading">3.1] Voluntary Terminations</h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            A voluntary termination of employment occurs when an employee
            submits a written or verbal notice of resignation to his or her
            supervisor or when an employee is absent from work for three
            consecutive workdays and fails to contact his or her supervisor (job
            abandonment).
            <br />
            <h5 className="policy-section1-heading my-2 fw-bold">
              Procedures{' '}
            </h5>
            <ol>
              <li className="py-2">
                Employees are requested to provide a minimum of two months'
                notice of their intention to separate from the company to allow
                a reasonable amount of time to transfer ongoing workloads. The
                employee should provide a written resignation notification to
                his or her manager.
              </li>

              <li className="py-2">
                Upon receipt of an employee's resignation, the manager will
                notify the human resource (HR) department by sending a copy of
                the resignation letter and any other pertinent information
                (i.e., employee's reason for leaving, last day of work).
              </li>

              <li className="py-2">
                The HR department will coordinate the employee's out-processing.
                This process will include the employee's returning all company
                property (computers, documentation, keys, etc.); a review of the
                employee's post termination benefits status; and the employee's
                completion of an exit interview.
              </li>

              <li className="py-2">
                The employee's manager will complete a Supervisory Termination
                Summary and deliver the completed form to HR.
              </li>

              <li className="py-2">
                Employees who possess a security clearance (security codes to
                the building, computer passwords, Softer ware/email etc.) must
                meet with administration for a debriefing no later than their
                last day of employment.
              </li>
            </ol>
          </span>
        </div>

        <h5 className="policy-section1-heading">
          3.2] Involuntary Terminations
        </h5>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            An involuntary termination of employment, including layoffs of over
            30 days, is a management-initiated dismissal with or without cause.
            The inability of an employee to perform the essential functions of
            his or her job with or without a reasonable accommodation may also
            result in an involuntary termination. An employee may also be
            discharged for any legal reason, including but not limited to:
            misconduct, tardiness, absenteeism, unsatisfactory performance or
            inability to perform.
            <br />
            <h5 className="policy-section1-heading my-2 fw-bold">
              Procedures{' '}
            </h5>
            <ol>
              <li className="py-2">
                I. Before any action is taken to involuntarily discharge an
                employee, the employee's manager must request a review by HR and
                the employee's immediate supervisor.
              </li>

              <li className="py-2">
                II. The termination review staff will be responsible for
                reviewing the circumstances and determining if discharge is
                warranted. If the staff recommends discharge, the employee's
                manager and an HR representative will notify the employee. The
                employee's manager should complete an Employee Change Form and
                notify HR and payroll of the last day worked by the employee
              </li>
            </ol>
          </span>
        </div>
        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            <br />
            <h5 className="policy-section1-heading my-2 fw-bold">
              Full and Final Settlement
            </h5>
            <ul>
              <li className="py-2" style={{ listStyle: 'square' }}>
                He/she will be paid any amount so due, on the day on which he
                /she is relived Any amount due on account of Gratuity payable by
                the company will be paid, within 30 days from the receipt of
                claim from the employee in the prescribed form. However, the
                management reserves the right to withhold payment due on full
                and final settlement in case there is any recovery pending from
                the employee in respect of any advance, loans materials, assets
                etc.
              </li>
            </ul>
          </span>
        </div>

        <div className="d-flex flex-column first-para-container">
          <span className="first-para px-4">
            <br />
            <h5 className="policy-section1-heading my-2 fw-bold">
              Termination Conditions
            </h5>
            An employee may be terminated for various reasons, including:
            <ul>
              <li className="py-2" style={{ listStyle: 'square' }}>
                <b>Performance issues&nbsp;:&nbsp;</b> If an employee
                consistently fails to meet the expectations and standards of
                their job, despite receiving warnings and opportunities to
                improve, they may be terminated.
              </li>

              <li className="py-2" style={{ listStyle: 'square' }}>
                <b>Misconduct&nbsp;:&nbsp;</b>If an employee engages in
                misconduct such as theft, harassment, or any other violation of
                company policies, they may be terminated.
              </li>

              <li className="py-2" style={{ listStyle: 'square' }}>
                <b>Breach of contract&nbsp;:&nbsp;</b>If an employee violates
                the terms of their employment contract, such as disclosing
                confidential information, they may be terminated.{' '}
              </li>

              <li className="py-2" style={{ listStyle: 'square' }}>
                <b>Redundancy&nbsp;:&nbsp;</b>If an employee's role is no longer
                required due to changes in the organization's structure, or if
                the company needs to reduce its workforce, they may be
                terminated.{' '}
              </li>

              <li className="py-2" style={{ listStyle: 'square' }}>
                <b>Health reasons: </b>If an employee is unable to perform their
                duties due to health issues or disability, they may be
                terminated if the employer is unable to accommodate their needs.
              </li>

              <li className="py-2" style={{ listStyle: 'square' }}>
                <b>Resignation: </b>
                <ul>
                  <li className="py-2" style={{ listStyle: 'number' }}>
                    An employee may choose to resign from their job, in which
                    case they are not terminated by the employer.
                  </li>
                  <li className="py-2" style={{ listStyle: 'number' }}>
                    It's important to note that termination must be done in
                    accordance with applicable laws and regulations, and the
                    process must be fair and just.
                  </li>

                  <li className="py-2" style={{ listStyle: 'number' }}>
                    Employers must provide employees with a notice period,
                    severance pay (if applicable), and a valid reason for
                    termination.
                  </li>

                  <li className="py-2" style={{ listStyle: 'number' }}>
                    Additionally, employers must comply with all relevant laws
                    and regulations, including labour laws, anti-discrimination
                    laws, and employment contract.
                  </li>
                </ul>
              </li>
            </ul>
          </span>
        </div>
      </section>

      <PolicyFooter />
    </div>
  );
};

export default Termination;
